import React, { FC } from "react"
import { Helmet } from "react-helmet"

type Props = {
  seo?: {
    title?: string
    link?: string
    description?: string
    keywords?: string
  }
  cardImage?: {
    image?: string
  }
  meta?: {
    name
    content
  }[]
}

const SEO: FC<Props> = ({ seo, meta, cardImage }) => {
  const ldJson = {
    "@context": ["https://schema.org"],
    "@type": "Organization",
    url: ["https://www.climatecheck.com"],
    logo: "https://climatecheck.com/logo-new-r-green.svg",
    name: "ClimateCheck",
    description:
      "ClimateCheck provides detailed climate risk data and reports for individual properties, portfolios and geographic corridors so you can assess your exposure to extreme weather events now and into the future.",
    address: {
      "@type": "PostalAddress",
      streetAddress: "400 Village Square Blvd",
      addressLocality: "Tallahassee",
      addressCountry: "USA",
      addressRegion: "FL",
      postalCode: "32312",
    },
    contactPoint: {
      "@type": "ContactPoint",
      email: "info@climatecheck.com",
    },
  }

  const defaultImage = "/climatecheck_preview.jpg"
  const siteUrl = "https://climatecheck.com"

  return (
    <Helmet meta={meta}>
      <meta property="og:title" content={seo?.title || ""} />
      <meta property="og:description" content={
        seo?.description ||
        "Complete and reliable climate risk data and solutions for real estate."
      } />
      <meta property="og:url" content={seo?.link || siteUrl} />
      <meta property="og:image" content={cardImage?.image ? cardImage.image : `${siteUrl}${defaultImage}`} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="ClimateCheck" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:type" content="image/png" />

      <html lang="en" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="shortcut icon" href="/favicon.ico" />
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="192x192" href="/android-chrome-192x192.png" />
      <link rel="icon" type="image/png" sizes="512x512" href="/android-chrome-512x512.png" />
      
      <link
        rel="preload"
        as="font"
        href={"https://fonts.gstatic.com/s/muli/v22/7Auwp_0qiz-afTLGLQ.woff2"}
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <title> {seo?.title || ""} / ClimateCheck</title>
      {seo?.link && <link rel="canonical" href={seo.link} />}
      <meta name="twitter:url" content={seo?.link || siteUrl} />
      <meta
        name="description"
        content={
          seo?.description ||
          "We empower property buyers, owners, and brokers by exposing and quantifying the risks related to the climate crisis through our proprietary risk assessment and report."
        }
      />
      {seo?.keywords && <meta name="keywords" content={seo.keywords} />}

      {/* TWITTER meta tags for sharing */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:site" content="climatecheck.com" />
      {seo?.title && <meta name="twitter:title" content={seo?.title} />}
      <meta
        property="twitter:description"
        content={
          seo?.description ||
          "We empower property buyers, owners, and brokers by exposing and quantifying the risks related to the climate crisis through our proprietary risk assessment and report."
        }
      />
      <meta
        property="twitter:image"
        content={cardImage?.image ? cardImage.image : `${siteUrl}${defaultImage}`}
      />

      {/* META meta tags for sharing */}
      <meta
        name="description"
        property="og:description"
        content={
          seo?.description ||
          "We empower property buyers, owners, and brokers by exposing and quantifying the risks related to the climate crisis through our proprietary risk assessment and report."
        }
      />
      <meta
        name="image"
        property="og:image"
        itemProp="image"
        content={cardImage?.image ? cardImage.image : `${siteUrl}${defaultImage}`}
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />
      {seo?.link && <meta property="og:url" content={seo.link} />}
      <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
    </Helmet>
  )
}

export default SEO
